import React from 'react'
import './about.css'
import Image from '../../assets/profile-image-2.jpg'

export default function About() {
  return (
    <section className='about container section' id='about'>
      <h2 className='section_title'>About Me</h2>

      <div className='about_container grid'>
        <img src={Image} alt="" className='about_img' />

        <div className='about_data grid'>
          <div className='about_info'>
            <p className='about_description'>As an enthusiastic iOS developer with 5 years of experience, I have a track record of delivering projects that seamlessly integrate robust functionality with intuitive user experiences. Whether tackling complex features or optimizing performance, my versatile skill set allows me to adapt to evolving technologies and industry trends, ensuring the apps I develop stay innovative. I excel in collaborative environments, utilizing effective communication and problem-solving skills to drive project success.</p>
            <a href='https://drive.google.com/file/d/1C47uYAvTAevZQbnMoayjb9fav09_BJJR/view?usp=sharing' className='btn'>Download CV</a>
          </div>
        </div>
      </div>
    </section>
  )
}
