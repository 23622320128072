import React from 'react'

export default function ProjectCard(props) {
  return (
    <div className='work_card'>
        <div className='work_thumbnail'>
        <img src={props.image} alt='' className='work_img' />
        <div className='work_mask'></div>
        </div>

        <span className='work_category'>{props.category}</span>
        <h3 className='work_title'>{props.title}</h3>
        <a href={props.link} className='work_button'>
        <i className='icon-link work__button-icon' />
        </a>
    </div>
  )
}
