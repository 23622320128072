import React, { useState } from 'react'
import './sidebar.css'

export default function Sidebar() {

    const [toogle, showMenu] = useState(false)

    return (
        <>
            <aside className={toogle ? 'aside show-menu' : 'aside'}>
                <nav className='nav'>
                    <div className='nav_menu'>
                        <ul className='nav_list'>
                            <li className='nav_item'>
                                <a href="#home" className='nav_link'>
                                    <span class="icon-home"></span>
                                </a>
                            </li>

                            <li className='nav_item'>
                                <a href="#about" className='nav_link'>
                                    <span class="icon-user-following"></span>
                                </a>
                            </li>

                            <li className='nav_item'>
                                <a href="#resume" className='nav_link'>
                                    <span class="icon-graduation"></span>
                                </a>
                            </li>

                            <li className='nav_item'>
                                <a href="#work" className='nav_link'>
                                    <span class="icon-layers"></span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </nav>
            </aside>

            <div className={toogle ? 'nav_toogle nav_toogle-open' : 'nav_toogle'} onClick={() => showMenu(!toogle)}>
                <span class="icon-menu"></span>
            </div>
        </>
    )
}
