import './App.css';
import About from './components/about/About';
import Home from './components/home/Home';
import Resume from './components/resume/Resume';
import Work from './components/work/Work';
import Sidebar from './components/sidebar/Sidebar';

function App() {
  return (
    <div>
      <Sidebar />
      <main className='main'>
        <Home />
        <About />
        <Resume />
        <Work />
      </main>
    </div>
  );
}

export default App;
 