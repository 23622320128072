import React from 'react'

export default function ResumeCard(props) {
  return (
    <div className='timeline_item'>
        <i className={props.icon}></i>
        <span className='timeline_date'>{props.year}</span>
        <h3 className='timeline_title'>{props.title}</h3>
        <span className='timeline_organization'>{props.organization}</span>
        <p className='timeline_desc' dangerouslySetInnerHTML={{__html: props.desc.replace("<br />", "<br />")}} />
    </div>
  )
}
