import React from 'react'
import './home.css'
import Image from '../../assets/profile-image-1.jpeg'
import HeaderSocials from './HeaderSocials'

export default function Home() {
  return (
    <section className='home container' id='home'>
        <div className='intro'>
            <img src = {Image} alt='' className='home_img' />
            <h1 className='home_name'>Katy Lau</h1>
            <span className='home_education'>iOS developer</span>

            <HeaderSocials />

        </div>
    </section>
  )
}