import React from "react";
import "./resume.css";
import Card from "./ResumeCard";

export default function Resume() {
  return (
    <section className="resume container section" id="resume">
      <h2 className="section_title">Experience</h2>

      <div className="resume_container grid">
        <div className="timeline grid">
          <Card
            icon="icon-graduation"
            title="Bachelor's Degree in Computing"
            organization="The Hong Kong Polytechnic University"
            year="2015 - 2019"
            desc=""
          />
        </div>

        <div className="timeline grid">
          <Card
            icon="icon-briefcase"
            title="Mobile app developer (iOS developer)"
            organization="HomeStars"
            year="Jul 2022 - Oct 2023"
            desc="- Developed iOS applications using Swift and SwiftUI <br /> - Refactored legacy module from Objective-C to Swift <br /> - Implemented unit testing"
          />
          <Card
            icon="icon-briefcase"
            title="Senior mobile app developer"
            organization="Hundsun Ayers Technologies Limited"
            year="Jun 2019 - Apr 2022"
            desc="- Developed stock trading applcations for iOS and Android platforms <br /> - Developed the HSBC Broker iOS application from scratch <br /> - Revamped and upgraded the stock trading application using Flutter and WebSockets"
          />
        </div>
      </div>
    </section>
  );
}
