import React from 'react'
import './work.css'
import ProjectCard from './ProjectCard'
import HomeStarsPro from '../../assets/homestars-pro-thumbnail.PNG'
import HomeStars from '../../assets/homestars-thumbnail.PNG'
import HSBC from '../../assets/hsbc-thumbnail.PNG'

export default function Work() {
  return (
    <div className='work container section' id='work'>
      <h2 className='section_title'>Recent Works</h2>

      <div className='work_container grid'>
        <ProjectCard image={HomeStarsPro} category='HomeStars' title='HomeStars for Pros' link='https://apps.apple.com/ca/app/homestars-for-pros/id958027555' />
        <ProjectCard image={HomeStars} category='HomeStars' title='HomeStars' link='https://apps.apple.com/ca/app/homestars/id1370687620' />
        <ProjectCard image={HSBC} category='Hundsun Ayers' title='HSBC Broking Services' link='https://apps.apple.com/app/hsbc-broking-services/id1539653892' />
      </div>
    </div>
  )
}

