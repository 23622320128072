import React from 'react'

export default function HeaderSocials() {
  return (
    <div className='home_socials'>
        <a href='https://www.linkedin.com/in/katy-lty/' className='home_social-link'>
            <span class='icon-social-linkedin'></span>
        </a>

        <a href='https://github.com/tylau0822' className='home_social-link'>
            <span class='icon-social-github'></span>
        </a>
    </div>
  )
}
